import { Center, Container, Grid, Image, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { LandingPageIconCard } from "components/landing-page.icon-card"
import Desktop from "assets/desktop-arrow-down.svg"
import Mailbox from "assets/mailbox.svg"
import Money from "assets/money-coin-transfer.svg"

export function LandingPageHowItWorks() {
  const isMobile = useMediaQuery("(max-width: 82em)")

  return (
    <>
      <Center mt={"xl"}>
        <Text
          c={"red"}
          size="lg"
          fw={700}
          styles={{ root: { textAlign: "center" } }}
        >
          Our Process Is All About Ease and Efficiency
        </Text>
      </Center>
      <Center mt={"xs"} mb={"xl"}>
        <Text
          span
          fw={700}
          styles={{ root: { fontSize: 32, textAlign: "center" } }}
        >
          How Does The{" "}
          <Text
            span
            c="red"
            fw={700}
            styles={{ root: { fontSize: 32, textAlign: "center" } }}
          >
            "Sell My House Fast"
          </Text>{" "}
          Process REALLY Work?
        </Text>
      </Center>
      <Container size={"95%"}>
        <Grid columns={24} justify="center" align="center">
          <Grid.Col span={{ base: 24, lg: 5 }}>
            <LandingPageIconCard
              title="Submit a Request"
              description="Kick start the process by providing us with brief details
            about your home. You can click get started to fill out our
            online form or call and speak to one of our consultants."
              children={
                <Image
                  src={Desktop}
                  alt="desktop logo"
                  width={100}
                  height={100}
                  fallbackSrc="https://placehold.co/40x40"
                />
              }
            />
          </Grid.Col>
          {!isMobile && <Grid.Col span={1} />}
          <Grid.Col span={{ base: 24, lg: 5 }}>
            <LandingPageIconCard
              title="Review Your Offer"
              description="We will email you a no-obligation preliminary cash offer
            within the day after we have reviewed your home details."
              children={
                <Image
                  src={Mailbox}
                  alt="mailbox icon"
                  width={100}
                  height={100}
                  fallbackSrc="https://placehold.co/40x40"
                />
              }
            />
          </Grid.Col>
          {!isMobile && <Grid.Col span={1} />}
          <Grid.Col span={{ base: 24, lg: 5 }}>
            <LandingPageIconCard
              title="Close On Your Timeline"
              description="Once you accept our offer, you have the freedom to choose a
          closing date as soon as 7 days from acceptance. Our flexible
          closing schedule is designed to accomodate your timeline for
          maximum convenience."
              children={
                <Image
                  src={Money}
                  alt="money transfer icon"
                  width={100}
                  height={100}
                  fallbackSrc="https://placehold.co/40x40"
                />
              }
            />
          </Grid.Col>
        </Grid>
      </Container>
    </>
  )
}
