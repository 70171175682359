import { Button, Grid, Stack, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { ListReason } from "components"
import { useNavigate } from "react-router-dom"

export function LandingPageWhySell() {
  const isMobile = useMediaQuery("(max-width: 82em)")
  const navigate = useNavigate()

  return (
    <Stack p="xl" m={"lg"}>
      <Text c={"red"} styles={{ root: { fontSize: 18 } }}>
        Why Sell Your House To GK?
      </Text>
      <Grid justify="center" align="center">
        <Grid.Col span={isMobile ? 12 : 6}>
          <Stack>
            <Text span fw={700} styles={{ root: { fontSize: 28 } }}>
              We're Committed To{" "}
              <Text span fw={700} c={"red"} styles={{ root: { fontSize: 28 } }}>
                Fair Offers and Exceptional Service!
              </Text>
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col span={isMobile ? 12 : 6}>
          <Stack>
            <Button size="xl" onClick={() => navigate("/cash-offer")}>
              Get My Cash Offer
            </Button>
          </Stack>
        </Grid.Col>
      </Grid>
      <Grid columns={24} pl={"lg"} pr={"lg"} mt={isMobile ? "xl" : "inherit"}>
        <Grid.Col span={{ base: 24, md: 11, lg: 11 }}>
          <Stack gap={isMobile ? "xl" : 0}>
            <ListReason
              title="Easy To Understand Contract"
              description="We want you to feel comfortable with our offer and make sure that you understand everything before you sign any papers."
            />
            <ListReason
              title="We Take Care of All Expenses"
              description="You never have to worry about paying any commissions, repairs, or expenses when you sell to us. That means more profit for you."
            />
            <ListReason
              title="Close On Date Of Your Choice"
              description="Need to sell a house fast? We can buy your house quickly and on the date of your choice. Sometimes even in as little as 7 days."
            />
          </Stack>
        </Grid.Col>
        {!isMobile && <Grid.Col span={2} />}
        <Grid.Col
          span={{ base: 24, md: 11, lg: 11 }}
          mt={isMobile ? "xl" : "inherit"}
        >
          <Stack gap={isMobile ? 48 : 0}>
            <ListReason
              title="Sell Your House In Any Condition"
              description="It doesn't matter what the condition of your house is. We buy houses in perfect condition and in major need of repairs. Sell your house today!"
            />
            <ListReason
              title="Fast and Simple Buying Process"
              description="Our house buying process is the simple and easiest way for you to sell your house fast. Give us a call and find out how we do it."
            />
            <ListReason
              title="Honesty and Professionalism"
              description="It's our main goal to build strong and lasting relationships with our customers. We promise to always treat you with kindness and respect."
            />
          </Stack>
        </Grid.Col>
      </Grid>
    </Stack>
  )
}
