import { Button, Stack, TextInput, Textarea } from "@mantine/core"
import { useEffect } from "react"
import { useForm } from "@mantine/form"
import { IconArrowRight } from "@tabler/icons-react"
import axios from "axios"

interface FormValues {
  contactName: string
  contactEmail: string
  contactPhone: string
  message: string
}

interface Props {
  onClose: () => void
}

export function ContactForm({ onClose }: Props) {
  const initialValues: FormValues = {
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    message: "",
  }

  const form = useForm<FormValues>({
    initialValues,
    validate: (values) => {
      return {
        contactName: values.contactName.length <= 1 ? "Required" : null,
        contactEmail: /^\S+@\S+$/.test(values.contactEmail)
          ? null
          : "Invalid email",
        contactPhone:
          values.contactPhone.length < 6
            ? "Phone must include at least 10 characters"
            : null,
      }
    },
  })

  useEffect(() => {
    form.setValues(initialValues)
    form.resetDirty(initialValues)
  }, [])

  async function onSubmit() {
    if (form.validate().hasErrors) return

    const payload = {
      contactName: form.values.contactName,
      contactEmail: form.values.contactEmail,
      contactPhone: form.values.contactPhone,
      message: form.values.message,
    }
    try {
      await axios.post(
        `https://gk-buys-homes-5rhof.ondigitalocean.app/contact`,
        payload
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(`[submitContactForm] Error`, e.message)
      }
    }

    onClose()
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <TextInput
          label="Name"
          placeholder="Your name"
          key={"contactName"}
          {...form.getInputProps("contactName")}
        />
        <TextInput
          label="Email"
          placeholder="Your email"
          key={"contactEmail"}
          {...form.getInputProps("contactEmail")}
        />
        <TextInput
          label="Phone Number"
          placeholder="Your phone number"
          key={"contactPhone"}
          {...form.getInputProps("contactPhone")}
        />
        <Textarea
          label="Message"
          placeholder="Your message here..."
          minRows={4}
          key={"message"}
          {...form.getInputProps("message")}
        />
        <Button
          rightSection={<IconArrowRight size={18} />}
          onClick={onSubmit}
          size="md"
          fullWidth
          mt={"md"}
        >
          Submit
        </Button>
      </Stack>
    </form>
  )
}
