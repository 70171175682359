import {
  Burger,
  Center,
  Drawer,
  Group,
  Image,
  Stack,
  Text,
} from "@mantine/core"
import Sticky from "react-stickynode"
import Logo from "assets/logo.png"
import { COLORS } from "settings/app"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { IconPhone } from "@tabler/icons-react"
import { ContactForm } from "components/contact.form"
import { notifications } from "@mantine/notifications"

interface Props {
  children?: ReactNode
}

export function AppHeader({ children }: Props) {
  const isMobile = useMediaQuery("(max-width:82em)")
  const navigate = useNavigate()

  const [opened, { toggle, close }] = useDisclosure()

  return (
    <Sticky innerZ={1}>
      <Group
        pl={isMobile ? 8 : 83}
        pr={isMobile ? 8 : 83}
        pt={isMobile ? 8 : 30}
        pb={isMobile ? 8 : 30}
        h={109}
        justify="space-between"
        bg={COLORS.white}
        wrap="nowrap"
      >
        <Group style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <Image
            src={Logo}
            alt="gk properties logo"
            width={70}
            height={57}
            fallbackSrc="https://placehold.co/40x40"
          />
          <Stack gap={0}>
            <Text
              m={0}
              p={0}
              styles={{ root: { fontSize: 20 } }}
              fw={700}
              c={"red"}
            >
              GK Buys Homes
            </Text>
            <Center>
              <Text
                m={0}
                p={0}
                mt={-2}
                styles={{ root: { fontSize: 12 } }}
                fw={500}
                c={COLORS.black}
              >
                The Buyer You Can Trust
              </Text>
            </Center>
          </Stack>
        </Group>
        {children}
        <Group>
          {!isMobile && (
            <Text styles={{ root: { fontSize: 24 } }} fw={700} c={"red"}>
              <a
                href="tel:702-901-0492"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                (702) 901-0492
              </a>
            </Text>
          )}
          {isMobile && (
            <>
              <Burger
                opened={opened}
                onClick={toggle}
                aria-label="Toggle navigation"
                c={"red"}
                mr={"sm"}
              />
              <Drawer
                opened={opened}
                onClose={close}
                size={"80%"}
                position="right"
                title={
                  <Text
                    fw={700}
                    styles={{ root: { fontSize: 24, textAlign: "center" } }}
                  >
                    Contact Us
                  </Text>
                }
              >
                <Stack gap={0}>
                  <ContactForm
                    onClose={() => {
                      notifications.show({
                        color: "green",
                        title: "Success!",
                        message:
                          "Thank you for reaching out, we will get in touch with you soon.",
                      })
                      close()
                    }}
                  />
                  <Text styles={{ root: { fontSize: 18 } }} fw={700} mt={"xl"}>
                    Or call us at:
                  </Text>
                  <Group wrap="nowrap" align="center">
                    <IconPhone size={24} color="red" />
                    <Text
                      styles={{ root: { fontSize: 24 } }}
                      fw={700}
                      c={"red"}
                    >
                      <a
                        href="tel:702-901-0492"
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        (702) 901-0492
                      </a>
                    </Text>
                  </Group>
                </Stack>
              </Drawer>
            </>
          )}
        </Group>
      </Group>
    </Sticky>
  )
}
