import {
  BackgroundImage,
  Box,
  Button,
  Center,
  Stack,
  Text,
  em,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import Hero from "assets/hero.png"
import { useNavigate } from "react-router-dom"

export function LandingPageHero() {
  const isMobile = useMediaQuery("(max-width: 82em)")
  const navigate = useNavigate()

  return (
    <Box>
      <BackgroundImage
        src={Hero}
        h={isMobile ? 800 : 800}
        styles={{ root: { alignContent: "center" } }}
      >
        <Center mt={isMobile ? em(-150) : -80}>
          <Stack>
            <Center>
              <Text
                styles={{ root: { fontSize: 40, textAlign: "center" } }}
                fw={800}
                c={"red"}
              >
                Looking to sell your home on your own terms?
              </Text>
            </Center>
            <Center>
              <Text
                styles={{ root: { fontSize: 40, textAlign: "center" } }}
                fw={800}
                c={isMobile ? "black" : "red"}
              >
                GK is the cash buyer you can trust.
              </Text>
            </Center>
            <Center>
              <Button size={"xl"} onClick={() => navigate("/cash-offer")}>
                Get A Cash Offer
              </Button>
            </Center>
          </Stack>
        </Center>
      </BackgroundImage>
    </Box>
  )
}
