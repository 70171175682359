import {
  Stepper,
  Button,
  Group,
  TextInput,
  Center,
  Text,
  Container,
  SegmentedControl,
  NumberInput,
  Stack,
  Select,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import { StateSelect } from "components/common"
import { useState } from "react"
import { COLORS } from "settings"

export interface ICashOfferFormValues {
  propertyType: string
  timeline: string
  propertyCondition: string
  mortgageBalance?: number
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  contactName: string
  isOwner: string
  contactType: string
  contactEmail: string
  contactPhone: string
  time: string
  method: string
}

interface Props {
  onSubmit: (values: ICashOfferFormValues) => void
}

export function CashOfferForm({ onSubmit }: Props) {
  const isMobile = useMediaQuery("(max-width: 82em)")

  const [active, setActive] = useState(0)

  const form = useForm<ICashOfferFormValues>({
    initialValues: {
      propertyType: "Single-family home",
      timeline: "Within 7 days",
      propertyCondition: "Excellent",
      mortgageBalance: undefined,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      contactName: "",
      isOwner: "yes",
      contactType: "Spouse",
      contactEmail: "",
      contactPhone: "",
      time: "Morning",
      method: "Email",
    },

    validate: (values) => {
      if (active === 4) {
        return {
          mortgageBalance:
            values.mortgageBalance === undefined ? "Required" : null,
        }
      }
      if (active === 5) {
        return {
          address1: values.address1.length <= 1 ? "Required" : null,
          city: values.city.length <= 1 ? "Required" : null,
          state: values.state.length <= 1 ? "Required" : null,
          zip: values.zip.length <= 1 ? "Required" : null,
        }
      }
      if (active === 7) {
        return {
          contactName: values.contactName.length <= 1 ? "Required" : null,
          contactEmail: /^\S+@\S+$/.test(values.contactEmail)
            ? null
            : "Invalid email",
          contactPhone:
            values.contactPhone.length < 6
              ? "Phone must include at least 10 characters"
              : null,
        }
      }
      return {}
    },
  })

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current
      }
      return current < 9 ? current + 1 : current
    })

  const finish = () => {
    nextStep()

    if (onSubmit) onSubmit(form.values as ICashOfferFormValues)
  }

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current))

  return (
    <>
      <Stepper
        active={active}
        mb={48}
        iconSize={isMobile ? 52 : 42}
        styles={{
          separator: { display: isMobile ? "none" : "inherit" },
          step: { display: isMobile ? "none" : "inherit" },
        }}
      >
        <Stepper.Step label={"Welcome"} mb={"xl"}>
          <Container size={"sm"}>
            <Center>
              <Text
                styles={{ root: { fontSize: 24, textAlign: "center" } }}
                fw={700}
                c={COLORS.black}
              >
                Welcome to our Cash Offer Request Form! We're excited to learn
                more about your property and how we can help you sell it
                quickly.
              </Text>
            </Center>
          </Container>
        </Stepper.Step>

        <Stepper.Step label={"Type"} mb={"xl"}>
          <Text size="xl" fw={500} mt="md" mb={"sm"}>
            What type of property are you selling?
          </Text>
          <SegmentedControl
            orientation="vertical"
            fullWidth
            size="lg"
            radius="xs"
            data={[
              "Single-family home",
              "Multi-family home",
              "Condo",
              "Townhome",
              "Other",
            ]}
            {...form.getInputProps("propertyType")}
          />
        </Stepper.Step>

        <Stepper.Step label={"Timeline"} mb={"xl"}>
          <Text size="xl" fw={500} mt="md" mb={"sm"}>
            How quickly are you looking to sell?
          </Text>
          <SegmentedControl
            orientation="vertical"
            fullWidth
            size="lg"
            radius="xs"
            data={[
              "Within 7 days",
              "Within 30 days",
              "More than 30 days",
              "More than 90 days",
            ]}
            {...form.getInputProps("timeline")}
          />
        </Stepper.Step>

        <Stepper.Step label={"Condition"} mb={"xl"}>
          <Text size="xl" fw={500} mt="md" mb={"sm"}>
            What is the overall condition of the property?
          </Text>
          <SegmentedControl
            orientation="vertical"
            fullWidth
            size="lg"
            radius="xs"
            data={["Excellent", "Good", "Fair", "Needs work", "Poor"]}
            {...form.getInputProps("propertyCondition")}
          />
        </Stepper.Step>

        <Stepper.Step label={"Mortgage"} mb={"xl"}>
          <Text size="xl" fw={500} mt="md" mb={"sm"}>
            Is there a mortgage on the property? If yes, what is the outstanding
            balance?
          </Text>
          <NumberInput
            size="lg"
            radius="xs"
            placeholder="Type your answer here..."
            prefix="$"
            allowNegative={false}
            decimalScale={2}
            thousandSeparator=","
            hideControls
            {...form.getInputProps("mortgageBalance")}
          />
        </Stepper.Step>

        <Stepper.Step label={"Address"} mb={"xl"}>
          <Stack>
            <Text size="xl" fw={500} mt="md" mb={"sm"}>
              What's the address of the property you're selling?
            </Text>
            <TextInput
              label="Address"
              placeholder="65 Hansen Way"
              {...form.getInputProps("address1")}
            />
            <TextInput
              label="Address Line 2"
              placeholder="Apartment 4"
              {...form.getInputProps("address2")}
            />
            <TextInput
              label="City"
              placeholder="Palo Alto"
              {...form.getInputProps("city")}
            />
            <StateSelect
              label="State"
              placeholder="California"
              {...form.getInputProps("state")}
            />
            <TextInput
              label="Zip Code"
              placeholder="94304"
              {...form.getInputProps("zip")}
            />
          </Stack>
        </Stepper.Step>

        <Stepper.Step label={"Ownership"} mb={"xl"}>
          <Stack>
            <Text size="md" fw={500} mt="md" mb={0}>
              Are you the owner and/or on the title for the property?
            </Text>
            <SegmentedControl
              orientation="horizontal"
              size="sm"
              radius="xs"
              data={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
              {...form.getInputProps("isOwner")}
            />
            {form.values.isOwner === "no" && (
              <Stack gap={0}>
                <Text size="md" fw={500} mt="md" mb={0}>
                  What is your relationship to the owner?
                </Text>
                <Select
                  data={["Spouse", "Listing Agent", "Friend", "Relative"]}
                  {...form.getInputProps("contactType")}
                />
              </Stack>
            )}
          </Stack>
        </Stepper.Step>

        <Stepper.Step label={"Contact"} mb={"xl"}>
          <Stack>
            <TextInput
              label="What's your name?"
              placeholder="Type your answer here..."
              {...form.getInputProps("contactName")}
            />
            <TextInput
              label="What's the best email address to reach you at?"
              placeholder="name@example.com"
              {...form.getInputProps("contactEmail")}
            />
            <TextInput
              label="What's your phone number?"
              placeholder="(201) 555-123"
              {...form.getInputProps("contactPhone")}
            />
            <Text size="md" fw={500} mt="md" mb={0}>
              How do you prefer to be contacted?
            </Text>
            <SegmentedControl
              orientation="horizontal"
              size="sm"
              radius="xs"
              data={["Email", "Phone Call", "Text Message"]}
              {...form.getInputProps("method")}
            />
            <Text size="md" fw={500} mt="md" mb={0}>
              What's the best time of day to reach you?
            </Text>
            <SegmentedControl
              orientation="horizontal"
              size="sm"
              radius="xs"
              data={["Morning", "Afternoon", "Evening"]}
              {...form.getInputProps("time")}
            />
          </Stack>
        </Stepper.Step>
        <Stepper.Completed>
          <Container size={"sm"}>
            <Center>
              <Text
                styles={{ root: { fontSize: 24, textAlign: "center" } }}
                fw={700}
                c={COLORS.black}
              >
                Thanks for your submission. We will be in touch soon!
              </Text>
            </Center>
          </Container>
        </Stepper.Completed>
      </Stepper>

      <Group justify="flex-end" mt="xl">
        {active !== 0 && active !== 8 && (
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
        )}
        {active !== 8 && active !== 7 && (
          <Button onClick={nextStep}>Next step</Button>
        )}
        {active === 7 && <Button onClick={finish}>Submit</Button>}
      </Group>
    </>
  )
}
