import { Box, Center, Container, Stack } from "@mantine/core"
import { AppFooter, AppHeader, CashOfferPageCta } from "components"
import { CashOfferForm, ICashOfferFormValues } from "components/cash-offer.form"
import axios from "axios"
import { COLORS } from "settings"
import { useMediaQuery } from "@mantine/hooks"

export interface ICashOfferPayload {
  propertyType: string
  timeline: string
  propertyCondition: string
  mortgageBalance?: number
  address: string
  contactName: string
  contactType: string
  contactEmail: string
  contactPhone: string
  preferences: IPreferences
}

export interface IPreferences {
  time: string
  method: string
}

export function CashOfferPage() {
  const isMobile = useMediaQuery("(max-width: 82em)")

  async function onSubmit(values: ICashOfferFormValues) {
    const payload: ICashOfferPayload = {
      propertyType: values.propertyType,
      timeline: values.timeline,
      propertyCondition: values.propertyCondition,
      mortgageBalance: values.mortgageBalance,
      address: `${values.address1} ${values.address2}, ${values.city}, ${values.state} ${values.zip}`,
      contactName: values.contactName,
      contactType: values.isOwner ? "Owner" : values.contactType,
      contactEmail: values.contactEmail,
      contactPhone: values.contactPhone,
      preferences: {
        time: values.time,
        method: values.method,
      },
    }

    try {
      await axios.post(
        `https://gk-buys-homes-5rhof.ondigitalocean.app/cash-offer`,
        payload
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(`[submitCashOfferForm] Error`, e.message)
      }
    }
  }

  return (
    <Box
      style={{
        overflowX: "hidden",
        minHeight: "100vh",
        height: isMobile ? "inherit" : "100vh",
      }}
    >
      <Stack
        gap={0}
        styles={{
          root: { minHeight: "100vh", height: isMobile ? "inherit" : "100vh" },
        }}
      >
        <AppHeader />

        <Center h={"100%"} mb={isMobile ? 48 : 0}>
          <Container size={"100%"}>
            <CashOfferForm onSubmit={onSubmit} />
          </Container>
        </Center>
        <Box style={{ backgroundColor: COLORS.tertiary }}>
          <CashOfferPageCta />
        </Box>

        <AppFooter />
      </Stack>
    </Box>
  )
}
