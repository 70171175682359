import { Box, Center, Group, Paper, Stack, Text } from "@mantine/core"
import { COLORS } from "settings/app"
import {
  AppFooter,
  AppHeader,
  ContactModal,
  LandingPageCta,
  LandingPageHero,
  LandingPageHowItWorks,
} from "components"
import { LandingPageWhySell } from "components/landing-page.why-sell"
import {
  useDisclosure,
  useMediaQuery,
  useScrollIntoView,
  useViewportSize,
} from "@mantine/hooks"
import { useEffect } from "react"
import { useForm } from "@mantine/form"

interface FormValues {
  contactName: string
  contactEmail: string
  contactPhone: string
  message: string
}

export function LandingPage() {
  const { width } = useViewportSize()
  const isMobile = useMediaQuery("(max-width:82em)")

  const [opened, { open, close }] = useDisclosure(false)

  const { scrollIntoView: scrollHowItWorks, targetRef: howItWorksRef } =
    useScrollIntoView<HTMLDivElement>({
      offset: 100,
    })

  const { scrollIntoView: scrollWhySell, targetRef: whySellRef } =
    useScrollIntoView<HTMLDivElement>({
      offset: 100,
    })

  function calculcateWhySellMargin() {
    if (isMobile) {
      return 16
    } else {
      return (width / 15) * -1
    }
  }

  const initialValues: FormValues = {
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    message: "",
  }

  const form = useForm<FormValues>({
    initialValues,
  })

  useEffect(() => {
    form.setValues(initialValues)
    form.resetDirty(initialValues)
  }, [])

  return (
    <Stack gap={0} styles={{ root: { overflowX: "hidden" } }}>
      <AppHeader
        children={
          !isMobile && (
            <Group gap={80} ml={-45}>
              <Text
                styles={{ root: { fontSize: 18, cursor: "pointer" } }}
                fw={700}
                onClick={() => scrollHowItWorks({})}
              >
                How It Works
              </Text>
              <Text
                styles={{ root: { fontSize: 18, cursor: "pointer" } }}
                fw={700}
                onClick={() => scrollWhySell({})}
              >
                Why Sell To Us
              </Text>
              <Text
                styles={{ root: { fontSize: 16, cursor: "pointer" } }}
                fw={700}
                onClick={open}
              >
                Contact Us
              </Text>
            </Group>
          )
        }
      />
      {!isMobile && <ContactModal opened={opened} onClose={close} />}
      <LandingPageHero />
      {isMobile ? (
        <Box bg={COLORS.secondary} pb={"lg"} ref={howItWorksRef}>
          <LandingPageHowItWorks />
        </Box>
      ) : (
        <Box bg={COLORS.secondary} h={700} ref={howItWorksRef}>
          <LandingPageHowItWorks />
        </Box>
      )}
      <Box mt={calculcateWhySellMargin()} mb={"xl"} ref={whySellRef}>
        {isMobile ? (
          <LandingPageWhySell />
        ) : (
          <Center>
            <Paper withBorder shadow="md" p="lg" radius={"lg"} w={"60%"}>
              <LandingPageWhySell />
            </Paper>
          </Center>
        )}
      </Box>
      <Box style={{ backgroundColor: COLORS.tertiary }}>
        <LandingPageCta />
      </Box>
      <AppFooter />
    </Stack>
  )
}
