import { Stack } from "@mantine/core"
import { Outlet } from "react-router-dom"
import { COLORS } from "settings/app"

import "@mantine/core/styles.css"

export function AppWrapper() {
  return (
    <div
      style={{
        backgroundColor: COLORS.white,
        width: "100%",
        height: "100vh",
      }}
    >
      <Stack gap={0}>
        <Outlet />
      </Stack>
    </div>
  )
}
