import { Box, Modal, Text } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { ContactForm } from "components/contact.form"

interface Props {
  opened: boolean
  onClose: () => void
}

export function ContactModal({ opened, onClose }: Props) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Text styles={{ root: { fontSize: 24 } }} fw={700}>
          Contact Us
        </Text>
      }
      size={"xl"}
    >
      <Box p="xs">
        <ContactForm
          onClose={() => {
            notifications.show({
              color: "green",
              title: "Success!",
              message:
                "Thank you for reaching out, we will get in touch with you soon.",
            })
            onClose()
          }}
        />
      </Box>
    </Modal>
  )
}
