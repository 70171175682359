import { Box, Center, Stack, Text } from "@mantine/core"
import { COLORS } from "settings/app"
import React from "react"

interface Props {
  title: string
  description: string
  children?: React.ReactNode
}

export function LandingPageIconCard({ title, description, children }: Props) {
  return (
    <Box
      pt={"lg"}
      pb={"lg"}
      pl={"sm"}
      pr={"sm"}
      style={{ backgroundColor: COLORS.white, height: 300 }}
    >
      <Stack gap={0}>
        <Center>{children}</Center>
        <Center mt={0}>
          <Text
            c="gray"
            fw={600}
            styles={{ root: { fontSize: 20, textAlign: "center" } }}
          >
            {title}
          </Text>
        </Center>
        <Center p={"lg"}>
          <Text
            fw={700}
            styles={{
              root: { fontSize: 14, textAlign: "center", lineHeight: 1.25 },
            }}
          >
            {description}
          </Text>
        </Center>
      </Stack>
    </Box>
  )
}
