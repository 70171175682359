import { Box, Grid, Stack, Text } from "@mantine/core"
import "@mantine/core/styles.css"
import { COLORS } from "settings/app"
import { IconCircleCheck } from "@tabler/icons-react"
import { useMediaQuery } from "@mantine/hooks"

interface Props {
  title: string
  description: string
}

export function ListReason({ title, description }: Props) {
  const isMobile = useMediaQuery("(max-width: 82em)")

  return (
    <Stack mt={"sm"} mb={"sm"} gap={0}>
      <Grid columns={isMobile ? 12 : 24}>
        <Grid.Col span={isMobile ? 1.5 : 1.5} mt={2}>
          <IconCircleCheck color={COLORS.orange} />
        </Grid.Col>
        <Grid.Col span={isMobile ? 10.5 : 22.5}>
          <Text fw={700} styles={{ root: { fontSize: 18 } }}>
            {title}
          </Text>
        </Grid.Col>
      </Grid>
      <Grid columns={isMobile ? 12 : 24}>
        <Grid.Col span={isMobile ? 1.5 : 1.5} />
        <Grid.Col span={isMobile ? 10.5 : 22.5}>
          <Box style={{ height: 100 }}>
            <Text styles={{ root: { fontSize: 16 } }}>{description}</Text>
          </Box>
        </Grid.Col>
      </Grid>
    </Stack>
  )
}
