import { Box, Center, Stack, Text } from "@mantine/core"
import { COLORS } from "settings/app"

export function AppFooter() {
  return (
    <Box bg={"red"}>
      <Stack gap={0}>
        <Center p={"sm"} pt={"xl"}>
          <Text fw={700} c={COLORS.white} style={{ textAlign: "center" }}>
            *If your property is currently listed this is not intended as a
            solicitation*
          </Text>
        </Center>
        <Center p={"sm"}>
          <Text fw={700} c={COLORS.white} style={{ textAlign: "center" }}>
            Owner is licensed Real Estate Agent in the State of Nevada License
            #B.1001699
          </Text>
        </Center>
        <Center p={"xl"}>
          <Text fw={700} c={COLORS.white} style={{ textAlign: "center" }}>
            © 2024 GK Properties. All Rights Reserved.
          </Text>
        </Center>
      </Stack>
    </Box>
  )
}
