import { Button, Center, Stack, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { useNavigate } from "react-router-dom"
import { COLORS } from "settings/app"

export function LandingPageCta() {
  const isMobile = useMediaQuery("(max-width: 82em)")
  const navigate = useNavigate()

  return (
    <Center p={"xl"} m={"lg"}>
      <Stack gap={0}>
        <Center>
          <Text
            fw={700}
            c={COLORS.white}
            styles={{
              root: { fontSize: isMobile ? 36 : 48, textAlign: "center" },
            }}
          >
            Skip the stress of a traditional sale
          </Text>
        </Center>
        <Center>
          <Text
            fw={700}
            c={COLORS.white}
            styles={{
              root: { fontSize: isMobile ? 36 : 48, textAlign: "center" },
            }}
          >
            and get a fair offer on your home today!
          </Text>
        </Center>
        <Center>
          <Button size="xl" mt={"lg"} onClick={() => navigate("/cash-offer")}>
            GET STARTED
          </Button>
        </Center>
      </Stack>
    </Center>
  )
}
