import { Center, Stack, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { COLORS } from "settings/app"

export function CashOfferPageCta() {
  const isMobile = useMediaQuery("(max-width: 82em)")

  return (
    <Center p={"xl"} m={"lg"}>
      <Stack gap={0}>
        <Center>
          <Text
            fw={700}
            c={COLORS.white}
            styles={{
              root: { fontSize: isMobile ? 24 : 36, textAlign: "center" },
            }}
          >
            Still Have Questions?
          </Text>
        </Center>
        <Center>
          <Text
            styles={{ root: { fontSize: isMobile ? 24 : 36 } }}
            fw={700}
            c={COLORS.white}
          >
            <a
              href="tel:702-901-0492"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              (702) 901-0492
            </a>
          </Text>
        </Center>
      </Stack>
    </Center>
  )
}
